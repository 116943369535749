var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.typeaheadState }, [
    _c(
      "div",
      {
        ref: "toggle",
        staticClass: "typeahead__toggle",
        on: { mousedown: _vm.toggle }
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search,
              expression: "search"
            }
          ],
          ref: "search",
          staticClass: "typeahead__search",
          attrs: { type: "text" },
          domProps: { value: _vm.search },
          on: {
            focus: _vm.onFocus,
            blur: _vm.onBlur,
            keydown: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape"
                  ])
                ) {
                  return null
                }
                return _vm.onEscapeKey($event)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown"
                  ])
                ) {
                  return null
                }
                return _vm.onDownKey($event)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp"
                  ])
                ) {
                  return null
                }
                return _vm.onUpKey($event)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onEnterKey($event)
              }
            ],
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.search = $event.target.value
              },
              _vm.onInput
            ]
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            ref: "text",
            staticClass: "typeahead__text",
            staticStyle: { visibility: "hidden" }
          },
          [_vm._v(_vm._s(_vm.displayText))]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            ref: "btn",
            staticClass: "typeahead__button desktop-only",
            attrs: { type: "button" },
            on: { click: _vm.onSearch }
          },
          [_vm._v("Search")]
        ),
        _vm._v(" "),
        _c("button", {
          staticClass: "btn-search fas fa-search mobile-only",
          attrs: { type: "submit" }
        })
      ]
    ),
    _vm._v(" "),
    _vm.open && _vm.options.length > 0
      ? _c("div", { ref: "list", staticClass: "list-wrap" }, [
          _c("span", { staticClass: "list-heading" }, [
            _vm._v("Search suggestions")
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "typeahead__list" },
            [
              _vm._l(_vm.options, function(option, index) {
                return _c(
                  "li",
                  { key: index, staticClass: "typeahead__item" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "typeahead__link",
                        class: [
                          _vm.selectIndex === index ? "typeahead__active" : ""
                        ],
                        on: {
                          mousedown: function($event) {
                            $event.preventDefault()
                            return _vm.select(option)
                          }
                        }
                      },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.copyWithHighlights(option.Title)
                            )
                          }
                        }),
                        _vm._v(" "),
                        option.Type
                          ? _c("span", { staticClass: "spacer" }, [_vm._v("|")])
                          : _vm._e(),
                        _vm._v(" "),
                        option.Type
                          ? _c("span", { staticClass: "type" }, [
                              _vm._v(_vm._s(option.Type))
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "typeahead__link all-results",
                    on: {
                      mousedown: function($event) {
                        $event.preventDefault()
                        return _vm.selectAll()
                      }
                    }
                  },
                  [
                    _vm._v(
                      '\n                    See All Results for "' +
                        _vm._s(_vm.currentSearchTerm) +
                        '"\n                '
                    )
                  ]
                )
              ])
            ],
            2
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }