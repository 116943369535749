var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "header-typeahead" } },
    [
      _c("typeahead", {
        attrs: { options: _vm.options, "current-search-term": _vm.searchQuery },
        on: {
          "update-search": _vm.searchNew,
          "update-typeahead": _vm.getTypeaheadItems
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }